@tailwind base;
@tailwind components;
@tailwind utilities;

.p-icon-field {
  width: 100%;
}
.p-password-input {
  width: 100%;
  padding: 10px;
  border: 1px solid rgba(187, 187, 187, 0.3);
  border-radius: 5px;
}

body {
  font-family: "Space Grotesk", sans-serif;
}
